import { Grid, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import React from 'react';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    postContainer: {
      padding: 20,
    },
    postTitle: {
      marginTop: 15,
      textAlign: 'left',
      fontWeight: 200,
      fontFamily: 'Roboto Mono',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    content: {
      marginTop: 15,
      textAlign: 'left',
      fontFamily: 'Roboto Mono',
      color: theme.palette.common.white,
      flexGrow: 1,
    },
    embedVideoContainer: {
      alignSelf: 'left',
    },
  })
);

export default function BlogPost({ data }) {
  const classes = useStyles();
  const post = data.markdownRemark;

  return (
    <div className={classes.root}>
      <Header title={'Zynth Punk'}/>

      <Grid className={classes.postContainer}>
        <Typography variant="h3" component="h3" className={classes.postTitle}>
          {post.frontmatter.title}
        </Typography>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <Footer />
      </Grid>
    </div>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
